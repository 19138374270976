class Logout {
	constructor(btns) {
		this.btns = btns
		if(this.btns.length == 0) return

		this.http = new XMLHttpRequest()

		this.init()
	}

	bindAll() {
		["logoutClick"].forEach( (fn) => (this[fn] = this[fn].bind(this)))
	}

	logoutClick(e) {
		e.preventDefault()
		console.log('click')

		let requestStr = 'action=ajaxlogout&security=' + ajax_login_object.logout_nonce

		let t = this
		this.http.onreadystatechange = function() {
			if(this.readyState == 4) {
				t.logout = JSON.parse(t.http.response)
				t.logoutRefresh()
			}
		}
		this.http.open('POST', ajax_login_object.ajaxurl, true)
		this.http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
		this.http.send(requestStr)
	}

	logoutRefresh() {
		if(this.logout.logout == true) {
			location.reload()
		}
	}

	init() {
		this.bindAll()
		this.btns.forEach( (btn) => {
			btn.addEventListener('click', this.logoutClick)
		})
	}
}

let btns = document.querySelectorAll('[data-logout="logout"]')
const logoutFunc = new Logout(btns)