class RegisterForm {
	constructor(container) {
		this.container = container
		if(!this.container) return

		this.fields = this.container.querySelectorAll('.register_field')
		this.btn = this.container.querySelector('.btn')

		this.status = this.container.querySelector('p.status')

		this.loginURL = ''

		this.isValid = false

		this.http = new XMLHttpRequest()

		this.init()
	}

	bindAll() {
		["fieldEvent", "register"].forEach( (fn) => (this[fn] = this[fn].bind(this)))
	}

	fieldEvent(e) {
		const el = e.srcElement
		this.fieldValidation(el)
	}

	fieldValidation(el) {
		const type = el.type,
			value = el.value,
			parent = el.closest(".register_field"),
			errorDisplay = parent.querySelector('.error'),
			label = parent.querySelector('label').innerText,
			required = el.required

		let isValid = false,
			errorMessage = ' ',
			showMessage = true
		
		el.classList.remove('invalid')
		el.classList.remove('valid')

		//if(required === false) return

		if(value == '') {
			isValid = false
			errorMessage = label + ' is required'
		} else {
			switch(type) {
				case 'text' :
					isValid = (value.length >= 2) ? true : false
					errorMessage = label + ' needs to be at least 2 characters'
					break
				case 'email' :
					const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					isValid = emailRegex.test(String(value).toLowerCase())
					errorMessage = 'Please supply a valid email address'
					break
				case 'tel' :
					const telRe = /^(\+[1-9]{1}[0-9]{3,14})?([0-9]{9,14})$/
					let telVal = value.replace(/\s+/g, '')
						telVal = telVal.replace('+', '')
					isValid = telRe.test(telVal)
					errorMessage = 'Please supply a valid phone number'
					break
				case 'password' :
					this.passwordValidation()
					showMessage = false
					break
			}
		}
		if(showMessage === true) {
			if(isValid) {
				errorDisplay.innerText = ''
				errorDisplay.classList.add('valid')
				el.classList.add('valid')
				el.classList.remove('invalid')
			} else {
				errorDisplay.classList.remove('valid')
				errorDisplay.innerText = errorMessage
				el.classList.add('invalid')
				el.classList.remove('valid')
			}
			this.isValid = isValid
		}
	}

	passwordCheck(el, val) {
		let isValid = true,
			errorMessage = ''

		//MIN LENGTH
		isValid = (val.length >= 8) ? true : false
		errorMessage = 'Password is at least 8 characters'
		if(!isValid) return errorMessage

		//MAX LENGTH
		isValid = (val.length <= 30) ? true : false
		errorMessage = 'Password is at most 30 characters'
		if(!isValid) return errorMessage

		//Includes Numbers
		const numberRegEx = /\d/
		isValid = numberRegEx.test(val)
		errorMessage = 'Password needs a number'
		if(!isValid) return errorMessage

		//Includes lowercase
		const lowerRegEx = /[a-z]/
		isValid = lowerRegEx.test(val)
		errorMessage = 'Password needs a lowercase letter'
		if(!isValid) return errorMessage

		//Includes uppercase
		const upperRegEx = /[A-Z]/
		isValid = upperRegEx.test(val)
		errorMessage = 'Password needs a uppercase letter'
		if(!isValid) return errorMessage

	}

	addMessage(input, message) {
		const errorDisplay = input.parentElement.querySelector('.error')
		if(message === '' || message === undefined) {
			errorDisplay.innerText = ''
			errorDisplay.classList.add('valid')
			input.classList.add('valid')
			input.classList.remove('invalid')
		} else {
			errorDisplay.classList.remove('valid')
			errorDisplay.innerHTML = message
			input.classList.add('invalid')
			input.classList.remove('valid')
		}
	}
	
	passwordValidation() {
		const passwordEntry = this.container.querySelector('#password_register'),
			passwordConfirm = this.container.querySelector('#password_register_valid'),
			passwordEntryVal = passwordEntry.value,
			passwordConfirmVal = passwordConfirm.value

		if(passwordEntryVal === '') return // No checks if first is empty
		
		let error = ''
		
		error = this.passwordCheck(passwordEntry, passwordEntryVal)
		this.addMessage(passwordEntry, error)
		
		if(passwordConfirmVal === '') return // NO checks if second is empty
		if(passwordEntryVal === passwordConfirmVal) {
			passwordEntry.classList.add('match')
			passwordConfirm.classList.add('match')
			this.addMessage(passwordConfirm, '')
		} else {
			this.addMessage(passwordConfirm, "Passwords don't match")
		}

	}

	addPhoneCountry() {
		const input = this.container.querySelector("input[type='tel'].validation");
		this.telField = intlTelInput(input, {
			//initialCountry: "gb",
			utilsScript: "/wp-content/themes/westay/js/utils.js",
			preferredCountries: ["gb", "us", "es"]
		});
	}


	register(e) {
		e.preventDefault()
		this.status.innerHTML = ''
		this.status.classList.remove('issue')
		this.status.classList.remove('success')
		let stepValid = true
		this.fields.forEach( (field) => {
			let input = field.querySelector('input.validation')
			this.fieldValidation(input)
			if(this.isValid == false) stepValid = false
		})

		if(stepValid === false) return

		let firstName = this.container.querySelector('input#first_name_register').value,
			lastName = this.container.querySelector('input#last_name_register').value,
			emailAddress = this.container.querySelector('input#email_register').value,
			phone = this.container.querySelector('input#phone_number_register').value,
			password = this.container.querySelector('input#password_register').value,
			security = this.container.querySelector('input#security_register').value
		
			phone = this.telField.getNumber()
	
		let requestStr = 'action=ajaxregister' + '&firstName=' + firstName + '&lastName=' + lastName + '&emailAddress=' + emailAddress + '&phone=' + phone + '&password=' + password+ '&security=' + security

		console.log(requestStr)

		let t = this
		this.status.innerHTML = 'Registering account... please wait'
		this.http.onreadystatechange = function() {
			if(this.readyState == 4) {
				t.registrationData = JSON.parse(t.http.response)
				t.processRegistration()
			}
		}
		this.http.open('POST', ajax_login_object.ajaxurl, true)
		this.http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
		this.http.send(requestStr)

	}

	processRegistration() {
		console.log(this.registrationData)
		if(this.registrationData.accountSetup === false) {
			this.status.innerHTML = this.registrationData.errorMessage
			this.status.classList.add('issue')
			return
		}
		if(this.registrationData.loggedin === false) {
			this.status.innerHTML = this.registrationData.message
			this.status.classList.add('issue')
			return
		}
		if(this.registrationData.loggedin === true) {
			this.status.innerHTML = 'Registration successful'
			this.status.classList.add('success')			
			setTimeout( () => {
				location.reload()
				checkout.changeActive(this.btn)
				this.setData()
			}, 500)
		}
	}

	setData() {
		let inputs = document.querySelectorAll('.booking_field input')
		if(!inputs) return
		inputs.forEach( (input) => {
			let inputId = input.id
			if(inputId === 'firstName') {
				input.value = this.registrationData.firstName
				checkout.fieldValidation(input)
			}
			if(inputId === 'lastName') {
				input.value = this.registrationData.lastName
				checkout.fieldValidation(input)
			}
			if(inputId === 'emailAddress') {
				input.value = this.registrationData.emailAddress
				checkout.fieldValidation(input)
			}
			if(inputId === 'phone') {
				input.value = this.registrationData.telephone
				checkout.fieldValidation(input)
			}
			
		})
	}

	init() {
		this.bindAll()
		this.addPhoneCountry()
		this.fields.forEach( (field) => {
			let input = field.querySelector('input.validation')
			input.addEventListener('change', this.fieldEvent)
		})
		this.btn.addEventListener('click', this.register)
	}
}

const ajaxRegisterContainer = document.querySelector('form#registerAjax')
const ajaxRegister = new RegisterForm(ajaxRegisterContainer)