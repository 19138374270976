class LoginForm {
	constructor(container) {
		this.container = container
		if(!this.container) return

		this.fields = this.container.querySelectorAll('.login_field')
		this.btn = this.container.querySelector('.btn')

		this.status = this.container.querySelector('p.status')

		this.loginURL = ''

		this.isValid = false

		this.http = new XMLHttpRequest()

		this.init()
	}

	bindAll() {
		["fieldEvent", "login"].forEach( (fn) => (this[fn] = this[fn].bind(this)))
	}

	fieldEvent(e) {
		const el = e.srcElement
		this.fieldValidation(el)
	}

	fieldValidation(el) {
		const type = el.type,
			value = el.value,
			errorDisplay = el.parentElement.querySelector('.error'),
			label = el.parentElement.querySelector('label').innerText

		let isValid = false,
			errorMessage = ' '
		
		el.classList.remove('invalid')
		el.classList.remove('valid')

		if(value == '') {
			isValid = false
			errorMessage = label + ' is required'
		} else {
			switch(type) {
				case 'email' :
					const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					isValid = emailRegex.test(String(value).toLowerCase())
					errorMessage = 'Please supply a valid email address'
					break
				case 'password' :
					isValid = (value.length >= 8) ? true : false
					errorMessage = 'Password is at least 8 characters'
					break
			}
		}
		if(isValid) {
			errorDisplay.innerText = ''
			errorDisplay.classList.add('valid')
			el.classList.add('valid')
			el.classList.remove('invalid')
		} else {
			errorDisplay.classList.remove('valid')
			errorDisplay.innerText = errorMessage
			el.classList.add('invalid')
			el.classList.remove('valid')
		}
		this.isValid = isValid
	}

	login(e) {
		e.preventDefault()
		this.status.innerHTML = ''
		this.status.classList.remove('issue')
		this.status.classList.remove('success')
		let stepValid = true
		this.fields.forEach( (field) => {
			let input = field.querySelector('input')
			this.fieldValidation(input)
			if(this.isValid == false) stepValid = false
		})

		if(stepValid === false) return

		let username = this.container.querySelector('input#email_login').value,
			password = this.container.querySelector('input#password_login').value,
			security = this.container.querySelector('input#security_login').value
	
		let requestStr = 'action=ajaxlogin' + '&username=' + username + '&password=' + password + '&security=' + security

		let t = this
		this.status.innerHTML = 'Logging in... please wait'
		this.http.onreadystatechange = function() {
			if(this.readyState == 4) {
				t.loginData = JSON.parse(t.http.response)
				t.processLogin()
			}
		}
		this.http.open('POST', ajax_login_object.ajaxurl, true)
		this.http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
		this.http.send(requestStr)

	}

	processLogin() {
		if(this.loginData.loggedin === false) {
			this.status.innerHTML = this.loginData.message
			this.status.classList.add('issue')
			return
		}
		if(this.loginData.loggedin === true) {
			this.status.innerHTML = 'Login successful'
			this.status.classList.add('success')
			console.log(this.loginData)
			setTimeout( () => {
				location.reload()
				//checkout.changeActive(this.btn)
				//this.setData()
			}, 500)
		}
	}

	setData() {
		let inputs = document.querySelectorAll('.booking_field input')
		if(!inputs) return
		inputs.forEach( (input) => {
			let inputId = input.id
			if(inputId === 'firstName') {
				input.value = this.loginData.firstName
				checkout.fieldValidation(input)
			}
			if(inputId === 'lastName') {
				input.value = this.loginData.lastName
				checkout.fieldValidation(input)
			}
			if(inputId === 'emailAddress') {
				input.value = this.loginData.emailAddress
				checkout.fieldValidation(input)
			}
			if(inputId === 'phone') {
				input.value = this.loginData.telephone
				checkout.fieldValidation(input)
			}
			
		})
	}

	init() {
		this.bindAll()
		this.fields.forEach( (field) => {
			let input = field.querySelector('input')
			input.addEventListener('change', this.fieldEvent)
		})
		this.btn.addEventListener('click', this.login)
	}
}

const ajaxLoginContainer = document.querySelector('form#loginAjax')
const ajaxLogin = new LoginForm(ajaxLoginContainer)

