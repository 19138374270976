class formToggle {
	constructor() {
		this.container = document.querySelector('.loginNav')
		if(!this.container) return

		this.loginBtn = this.container.querySelector('#loginBtn')
		this.registerBtn = this.container.querySelector('#registerBtn')
		
		this.loginAjax = document.querySelector('#loginAjax')
		this.registerAjax = document.querySelector('#registerAjax')

		this.init()
	}

	bindAll() {
		["toggleActive"].forEach( (fn) => (this[fn] = this[fn].bind(this)))
	}

	toggleActive(e) {
		const el = e.srcElement
		this.updateActive(el)
	}

	updateActive(el) {
		const id = el.id
		if(id !== this.loginBtn.id) {
			this.loginBtn.classList.remove('active')
			this.loginAjax.classList.remove('active')
			this.registerBtn.classList.add('active')
			this.registerAjax.classList.add('active')
		}
		if(id !== this.registerBtn.id) {
			this.registerBtn.classList.remove('active')
			this.registerAjax.classList.remove('active')
			this.loginBtn.classList.add('active')
			this.loginAjax.classList.add('active')
		}
	}

	init() {
		this.bindAll()
		this.loginBtn.addEventListener('click', this.toggleActive)
		this.registerBtn.addEventListener('click', this.toggleActive)
	}
}

const formToggleFn = new formToggle()